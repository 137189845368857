
import './styles.css'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import Nav from '../Nav'
import Footer from '../Footer'
import LazyImage from '../../services/LazyImage.jsx'

import ChevronRight from '../../SVGR/ChevronRight'
import Minimize from '../../SVGR/Minimize'
import services_page_img from '../../assets/services-page.jpg'

import GLOBAL from '../../services/GLOBAL.js'

export default function Services() {

    const [servicesData, setServicesData] = useState()

    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchServicesData = async () => {
            try {
                const { services_list } = await GLOBAL.CONTENT;
                setServicesData(services_list);
            } catch (error) {
                console.error('Failed to Services data:', error);
            }
        }; 

        fetchServicesData();
    }, [])

    const location = useLocation()
    const urlParams = new URLSearchParams(location.search)
    const [serviceGroup] = useState(urlParams.get('service_group'))

    const [spacerHeight, setSpacerHeight] = useState()

    useEffect(() => {
        const nav = document.getElementById('nav-bar')
        if (nav) {
            setSpacerHeight(nav.getBoundingClientRect().height)
        }
    }, [])

    return (
        <div className='services-page' >
            <Nav />

            <div className='hero-container'>
                <LazyImage 
                    className='image'
                    alt="floresta frondosa"
                    thumbnail = { services_page_img }
                    lightSource = { 'getImage/services-page.jpg' }
                    heavySource = { 'getImage/services-page.png' }
                    baseURL = { GLOBAL.BACKEND_BASE_URL }
                />

                <div className='nav-spacer' style={{ height: `${spacerHeight}px` }}></div>

                <div className='content-container'>
                    <h1 className='title'>Nossos Serviços</h1>
                    <p className='content'>
                        Oferecemos soluções personalizadas e inovadoras para sustentabilidade e eficiência.&nbsp;
                        Trabalhamos com gestão de resíduos, otimização de recursos naturais e eficiência energética.&nbsp;
                        Nossa abordagem é colaborativa e focada em resultados. Entre em contato para descobrir como&nbsp;
                        podemos ajudar sua empresa a crescer de forma sustentável.
                    </p>
                </div>
            </div>

            <ServicesFrame serviceGroup={ serviceGroup } servicesData={ servicesData } />
            <ServicesFrameMobile serviceGroup={ serviceGroup } servicesData={ servicesData } />

            <Footer />
        </div>
    )
}

function ServicesFrame({ serviceGroup, servicesData }) {
    
    const navigate = useNavigate()
    const goToContact = () => {
        navigate('/requestForProposal')
    }
    
    const [items, setItems] = useState({})

    const [state, setState] = useState({
        group: 1,
        services: [],
        page: 1
    })

    const [names, setNames] = useState([])

    useEffect(() => {

        if (!servicesData) return

        const newItems = {}
        const newNames = ['']

        let i = 1
        for (let title in servicesData) {
            newItems[`_${i}`] = servicesData[title]
            newNames[i] = title
            i ++ 
        }


        setItems(newItems)
        setNames(newNames)

        setState( prev => ({...prev,
            services: newItems[`_${prev.group}`],
        }))

    }, [servicesData, setNames, setItems, setState])

    useEffect(() => {

        if (serviceGroup && servicesData && Object.values(items).length !== 0 ) {
            const g = Object.keys(servicesData).indexOf(serviceGroup) + 1

            setState({
                group: g,
                services: items[`_${g}`],
                page: 1
            })
        }

    }, [serviceGroup, setState, items, servicesData])

    const [selection, setSelection] = useState({})

    const pagesAmount = () => Math.ceil(state.services.length/6)

    const getPageItems = () => {
        let firstIndex = state.page * 6 - 6
        let lastIndex = firstIndex + 6
        if (lastIndex > state.services.length) { lastIndex = state.services.length }
        const pageItems = state.services.slice(firstIndex, lastIndex)
        return pageItems
    }

    const nextGroup = () => {
        resetSelection()
        setState(prev => {
            let newState = {...prev}
            newState.group = newState.group >= Object.values(items).length ? 1 : newState.group + 1
            newState.services = items[`_${newState.group}`]
            newState.page = 1
            return newState
        })
    }

    const nextPage = () => {
        resetSelection()
        if (state.page === pagesAmount() ) { return nextGroup() }
        setState(prev => {
            const pagesAmnt = Math.ceil(prev.services.length/6)
            let newState = {...prev}
            newState.page = newState.page >= pagesAmnt ? 1 : newState.page + 1
            return newState
        })
    }

    const prevGroup = () => {
        setState(prev => {
            let newState = {...prev}
            newState.group = newState.group <= 1 ? Object.values(items).length : newState.group - 1
            newState.services = items[`_${newState.group}`]
            newState.page = Math.ceil(newState.services.length/6)
            return newState
        })
    }

    const prevPage = () => {
        if (state.page === 1 ) { return prevGroup() }
        setState(prev => {
            let newState = {...prev}
            newState.page = newState.page <= 1 ? newState.page - 1 : 1
            return newState
        })
    }

    const resetSelection = () => {
        setSelection({})
        getPageItems().forEach( item => {
            document.getElementById(item.title).style = ''
        })
    }

    const select = ({ title }) => {

        if (!title) { return }
        resetSelection()
        setSelection({[title]: true})

        const index = getPageItems().reduce((result, item, index) => item.title === title ? index : result, 0)

        const indexesToHide = []

        let counter = 0
        let direction = 1
        for (let i = index; counter < 3; counter ++) {

            i += direction

            if (i >= 6 || i <= 0) {
                i = index
                direction *= -1
                counter -= 1
                continue
            }

            indexesToHide.push(i)

        }

        indexesToHide.forEach( index => {
            const element = getPageItems()[index]
            if (!element) { return }
            const title = element.title
            document.getElementById(title).style = 'display: none;'
        })

        document.getElementById(title).style = index in [0, 2] ?
            'width: 28.5em; height: 100%; grid-column: 1 / 3; grid-row: 1 / 3;' :
            'width: 28.5em; height: 100%; grid-column: 2 / 4; grid-row: 1 / 3;'

    }

    const setPage = (page) => {
        resetSelection()
        setState(prev => ({...prev, page: page }))
    }

    return (
        <div className='services-list-container'>
            <div className='services-list-header'>
                <div className='service-group'>
                    { names[state.group] }
                </div>
                <div className='default-button' onClick={ nextGroup }>
                    MAIS
                    <ChevronRight className='service-group-chevron' stroke='#f5f5f5' strokeWidth={1} />
                </div>
            </div>

            <div className='services-list-content'> {
                getPageItems().map( service =>
                    <div className='service-item' id={service.title}
                        key={service.title}
                        style={ service.description ? null : {visibility: 'hidden'}}
                        onClick={ _ => { if (!selection[service.title]) { select(service) } } }
                    >
                        { selection[service.title] ?
                            <div className='service-item-container' style={{ cursor: "default" }}>
                                <div className='header'>
                                    <div className='title'>{service.title}</div>
                                    <div className='close-button default-button' onClick={ resetSelection }>
                                        <Minimize className="minimize-icon" stroke="#f5f5f5" strokeWidth={2} />
                                    </div>
                                </div>
                                <div className='service-item-description'>
                                    { service.description }
                                </div>
                                
                                <div className='service-item-cta default-button' onClick={ goToContact }>FALE COM NOSSA EQUIPE</div>
                            </div>
                            
                        : service.title }
                    </div>
                )
            } </div>

            <div className='services-list-footer'>
                <div className='default-button' onClick={ prevPage } style={{ marginRight: 'auto' }} >anterior</div>
                <div className='page-set'>{
                    Array.from({ length: pagesAmount() }, (_, i) => i + 1).map( (page, index, arr) =>
                        page ===
                            state.page ?
                            
                            <strong key={''+page+( randomHex() )}>
                                {page}{index < arr.length-1 ? ',' : null}
                            </strong>

                            : 
                            
                            <p key={(Math.round(Math.random()*255)).toString(16)}
                                onClick={ _ => setPage(page) }>
                                    {page}{index < arr.length-1 ? ',' : null}
                            </p>
                    )
                }</div>
                <div className='default-button' onClick={ nextPage }>Próximo</div>
            </div>
        </div>
    )
}

function ServicesFrameMobile({ serviceGroup, servicesData }) {

    const navigate = useNavigate()
    const goToContact = () => {
        navigate('/requestForProposal')
    }

    const [items, setItems] = useState({})

    const [state, setState] = useState({
        group: 1,
        services: [],
        page: 1
    })

    const [names, setNames] = useState([])

    useEffect(() => {

        if (!servicesData) return

        const newItems = {}
        const newNames = ['']

        let i = 1
        for (let title in servicesData) {
            newItems[`_${i}`] = servicesData[title]
            newNames[i] = title
            i ++ 
        }

        setItems(newItems)
        setNames(newNames)

        setState( prev => ({...prev,
            services: newItems[`_${prev.group}`],
        }))

    }, [servicesData, setNames, setItems, setState])

    useEffect(() => {

        if (serviceGroup && servicesData && Object.values(items).length !== 0 ) {
            const g = Object.keys(servicesData).indexOf(serviceGroup) + 1

            setState({
                group: g,
                services: items[`_${g}`],
                page: 1
            })

        }
    }, [serviceGroup, setState, items, servicesData])

    const [selection, setSelection] = useState({})

    const pagesAmount = () => Math.ceil(state.services.length/3)

    const getPageItems = () => {
        let firstIndex = state.page * 3 - 3
        let lastIndex = firstIndex + 3
        if (lastIndex > state.services.length) { lastIndex = state.services.length }
        const pageItems = state.services.slice(firstIndex, lastIndex)
        return pageItems
    }

    const nextGroup = () => {
        resetSelection()
        setState(prev => {
            let newState = {...prev}
            newState.group = newState.group >= Object.values(items).length ? 1 : newState.group + 1
            newState.services = items[`_${newState.group}`]
            newState.page = 1
            return newState
        })
    }

    const nextPage = () => {
        resetSelection()
        if (state.page === pagesAmount() ) { return nextGroup() }
        setState(prev => {
            const pagesAmnt = Math.ceil(prev.services.length/3)
            let newState = {...prev}
            newState.page = newState.page >= pagesAmnt ? 1 : newState.page + 1
            return newState
        })
    }

    const prevGroup = () => {
        setState(prev => {
            let newState = {...prev}
            newState.group = newState.group <= 1 ? Object.values(items).length : newState.group - 1
            newState.services = items[`_${newState.group}`]
            newState.page = Math.ceil(newState.services.length/3)
            return newState
        })
    }

    const prevPage = () => {
        if (state.page === 1 ) { return prevGroup() }
        setState(prev => {
            let newState = {...prev}
            newState.page = newState.page <= 1 ? newState.page - 1 : 1
            return newState
        })
    }

    const resetSelection = () => {
        setSelection({})
        getPageItems().forEach( item => {
            const component = document.getElementById(item.title+'-mobile')
           if (component) component.style = ''
        })
    }

    const select = ({ title }) => {

        if (!title) { return }
        resetSelection()
        setSelection({[title]: true})

        const index = getPageItems().reduce((result, item, index) => item.title === title ? index : result, 0)

        const indexesToHide = [0, 1, 2].filter( item => item !== index)

        indexesToHide.forEach( index => {
            const element = getPageItems()[index]
            if (!element) { return }
            const title = element.title
            const component = document.getElementById(title+'-mobile')
            if (component) component.style = 'display: none;'
        })

        
        const component = document.getElementById(title+'-mobile')
        if (component) component.style = 'height: fit-content; min-height: 31em; grid-row: 1 / 4;'

    }

    const setPage = (page) => {
        resetSelection()
        setState(prev => ({...prev, page: page }))
    }

    return (
        <div className='services-list-container mobile-services-list-container'>
            <div className='services-list-header'>
                <div className='service-group'>
                    { names[state.group] }
                </div>
                <div className='default-button' onClick={ nextGroup }>
                    MAIS
                    <ChevronRight className='service-group-chevron' stroke='#f5f5f5' strokeWidth={1} />
                </div>
            </div>

            <div className='services-list-content'> {
                getPageItems().map( service =>
                    <div className='service-item' id={service.title+'-mobile'}
                        key={service.title+'-mobile'}
                        style={ service.description ? null : {visibility: 'hidden'}}
                        onClick={ _ => { if (!selection[service.title]) { select(service) } } }
                    >
                        { selection[service.title] ?
                            <div className='service-item-container' style={{ cursor: "default" }}>
                                <div className='header'>
                                    <div className='title'>{service.title}</div>
                                    <div className='close-button default-button' onClick={ resetSelection }>
                                        <Minimize className="minimize-icon" stroke="#f5f5f5" strokeWidth={2} />
                                    </div>
                                </div>
                                <div className='service-item-description'>
                                    { service.description }
                                </div>
                                
                                <div className='service-item-cta default-button' onClick={ goToContact }>FALE COM NOSSA EQUIPE</div>
                            </div>
                            
                        : service.title }
                    </div>
                )
            } </div>

            <div className='services-list-footer'>
                <div className='default-button' onClick={ prevPage } style={{ marginRight: 'auto' }} >anterior</div>
                <div className='page-set'>{
                    Array.from({ length: pagesAmount() }, (_, i) => i + 1).map( (page, index, arr) =>
                        page ===
                            state.page ?
                            
                            <strong key={''+page+( randomHex() )}>
                                {page}{index < arr.length-1 ? ',' : null}
                            </strong>

                            : 
                            
                            <p key={''+page+( randomHex() )}
                                onClick={ _ => setPage(page) }>
                                    {page}{index < arr.length-1 ? ',' : null}
                            </p>
                    )
                }</div>
                <div className='default-button' onClick={ nextPage }>Próximo</div>
            </div>
        </div>
    )
}

function randomHex(){ return Math.round(Math.random()*17*10**6).toString(16) }
