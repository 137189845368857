import React from 'react';

/**
 * 
 * @param {String} props.stroke The color of the stroke.
 * @param {String} props.strokeWidth The width of the stroke.
 * @param {Number} props.bodyLength - The relative length of the arrow's body compared to its head. Defaults to 3 (body length = 3 * head length)
 * @returns {JSX.Element}
 */
const ArrowRight = (props) => {


  const commonProps = {...props}
  delete commonProps.bodyLength;
  const { stroke = "black", strokeWidth = 1, size = 20, bodyLength = 3 } = props

  const height = size;
  const width = bodyLength * 0.55 * height
  const thickness = height/10 * strokeWidth

  const tipWidth = height * .55

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      stroke={stroke}
      strokeWidth={thickness}
      strokeLinecap="round"
      {...commonProps}
    >
      {/* Arrow body */}
      <line x1={thickness} y1={height / 2} x2={width - thickness} y2={height / 2} />
      {/* Tip lines */}
      <line x1={width - thickness} y1={height / 2} x2={ width - tipWidth } y2={ height - thickness } />
      <line x1={width - thickness} y1={height / 2} x2={ width - tipWidth } y2={ thickness } />
    </svg>
  );
};

export default ArrowRight;
