
import React, { useState, useEffect, useCallback } from "react";

const CustomCursor = ({ children, parentRef, exitDelay = 0, onEnter = () => {}, onExit = () => {}  }) => {
    
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
    const [visible, setVisible] = useState(false);

    const [action, setAction] = useState(null)

    useEffect(() => {
        return (() => {
            document.documentElement.style.cursor = "initial";
        })
    }, []);

    useEffect(() => {

        let exitTimeout = null

        if (action === 'exit') {
            try { onExit() } catch (err) { console.error(err) };

            exitTimeout = setTimeout(() => {
                
                if (action !== 'exit') return

                if (parentRef.current) parentRef.current.style.cursor = "initial";
                setVisible(false);

            }, exitDelay);
        }

        if (action === 'enter') {

            try { onEnter() } catch (err) { console.error(err) };
        
            setVisible(true);
            if (parentRef.current) parentRef.current.style.cursor = "none";

        }

        return () => {
            if (exitTimeout) clearTimeout(exitTimeout)
        }

    }, [action, exitDelay, onEnter, onExit, parentRef])

    const handleMouseMove = useCallback((event) => {
        if (parentRef?.current) {
            const rect = parentRef.current.getBoundingClientRect()
            const x = event.clientX - rect.left
            const y = event.clientY - rect.top
            setCursorPosition({ x, y });
        }
    }, [parentRef]);

    const handleMouseEnter = useCallback(() => {
        setAction('enter')
    }, [setAction])

    const handleMouseLeave = useCallback(() => {
        setAction('exit')
    }, [setAction])

    useEffect(() => {

        const parent = parentRef ? parentRef.current : undefined
        if (!parent) return

        parent.addEventListener("mousemove", handleMouseMove);
        parent.addEventListener("mouseenter", handleMouseEnter);
        parent.addEventListener("mouseleave", handleMouseLeave);

        return () => {
            parent.removeEventListener("mousemove", handleMouseMove);
            parent.removeEventListener("mouseenter", handleMouseEnter);
            parent.removeEventListener("mouseleave", handleMouseLeave);
        };

    }, [handleMouseEnter, handleMouseLeave, handleMouseMove, parentRef]);

    return (
        <div
            className="custom-cursor"
            style={{
                left: `${cursorPosition.x}px`,
                top: `${cursorPosition.y}px`,
                display: visible ? "block" : "none",
                position: "absolute",
                pointerEvents: "none",
                zIndex: 10000
            }}
        >
            {children}
        </div>
    );
};

export default CustomCursor;
