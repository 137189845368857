
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import Arrow from '../../../SVGR/Arrow'
import X from '../../../SVGR/X'
import CheckMark from '../../../SVGR/CheckMark'

import { GlobalContext } from '../../../GlobalContext';

import lays from '../../../assets/lays-ipad-2.png'

import LazyImage from '../../../services/LazyImage'
import GLOBAL from '../../../services/GLOBAL'

const QuizSection = () => {

    const alternatives = useMemo(() => 
        [
            {
                text: 'R$500 bilhões',
                isCorrect: false
            },
            {
                text: '700 bilhões',
                isCorrect: false
            },
            {
                text: '1 trilhão',
                isCorrect: true
            },
            {
                text: '10 trilhões',
                isCorrect: false
            }
        ]
    , [])

    const [selectedAlternative, setSelectedAlternative] = useState()
    const [showPopUp, setShowPopUp] = useState(false)
    const popUpTimeoutRef = useRef()

    const imageRef = useRef()

    const handleConfirm = () => {
        popUpTimeoutRef.current = setTimeout(() => {
            setShowPopUp(false)
        }, 12000)
        setShowPopUp(true)
    };

    useEffect(() => {
        if (showPopUp === false && popUpTimeoutRef.current) {
            clearTimeout(popUpTimeoutRef.current)
            popUpTimeoutRef.current = null
        }
    }, [showPopUp])

    const handleChoose = (index) => {
        setSelectedAlternative(index);
    };

    const isCorrect = useCallback(() => {
        return alternatives[selectedAlternative]?.isCorrect || false
    }, [alternatives, selectedAlternative])

    useEffect(() => {

        const oberver = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible')
                }
            },
            {threshold: 0.2}
        )

        if (imageRef?.current) {
            oberver.observe(imageRef.current)
        }

        return () => {
            oberver.disconnect()
            popUpTimeoutRef?.current && clearTimeout(popUpTimeoutRef.current)
        }

    }, [])

    return (
        <section className="quiz-section">

            <FeedbackPopUp
                isCorrect={ isCorrect }
                show={showPopUp}
                setShow={setShowPopUp}
            />

            <div className="image-container desktop">

                <div className="image-frame">
                    
                    <div className="gradient-container">
                        <div className="gradient"></div>
                    </div>

                    <LazyImage
                        ref={imageRef}
                        className='image'
                        alt="Lays em pé segurando um IPad"
                        thumbnail = { lays }
                        lightSource = { 'getImage/lays-ipad-2-light.png' }
                        heavySource = { 'getImage/lays-ipad-2.png' }
                        baseURL = { GLOBAL.BACKEND_BASE_URL }
                    />
                    
                </div>

            </div>

            <div className="quiz-container">
                
                <h1 className='fade-in-effect'>Você sabe quanto o <strong>Itaú</strong> investirá em ESG até 2030?</h1>

                <form onSubmit={(e) => e.preventDefault()}>
                    {
                        alternatives.map((alternative, index) => (
                            <div className="alternative fade-in-effect" key={index}>
                                <input
                                    type="radio"
                                    name="alternative"
                                    id={`sec7-alternative-${index}`}
                                    checked={selectedAlternative === index}
                                    onChange={() => handleChoose(index)}
                                />
                                <label htmlFor={`sec7-alternative-${index}`}>{alternative.text}</label>
                            </div>
                        ))
                    }

                    <button
                        className='default-button'
                        onClick={handleConfirm}
                        style={ (selectedAlternative == null) ? { opacity: 0, transform: 'translateY(.5em)' } : {} }
                    >
                        Confirmar
                        <Arrow className='arrow' />
                    </button>

                </form>
                    
            </div>
                        
        </section>
    )
}

const FeedbackPopUp = ({ isCorrect, show, setShow }) => {

    const popupRef = useRef()
    const containerRef = useRef()
    const timeoutRef = useRef(null)

    const {weiManager} = useContext(GlobalContext)

    const freezeScroll = useCallback(() => {
        window.addEventListener('wheel', preventScroll, { passive: false });
        window.addEventListener('touchmove', preventScroll, { passive: false });
        window.addEventListener('keydown', preventKeydownScroll, { passive: false });
    }, []);
    
    const restoreScroll = useCallback(() => {
        window.removeEventListener('wheel', preventScroll);
        window.removeEventListener('touchmove', preventScroll);
        window.removeEventListener('keydown', preventKeydownScroll);
    }, []);
    
    const preventScroll = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    
    const preventKeydownScroll = (e) => {
        if (
            e.key === 'ArrowUp' || 
            e.key === 'ArrowDown' || 
            e.key === 'Space' || 
            e.key === 'PageUp' || 
            e.key === 'PageDown'
        ) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    const hidePopUp = useCallback(() => {

        if (popupRef?.current && containerRef?.current) {

            popupRef.current.classList.remove('visible')
            containerRef.current.classList.remove('visible')

            popupRef.current.classList.add('hidden')
            containerRef.current.classList.add('hidden')

        }

        timeoutRef.current = setTimeout(() => {
            restoreScroll()
            setShow(false)
        }, 400)

        
    }, [restoreScroll, setShow])

    const showPopUp = useCallback(() => {

        if (timeoutRef?.current) {
            clearTimeout(timeoutRef.current)
        }

        freezeScroll()
        setShow(true)
        
        if (popupRef?.current && containerRef?.current) {

            popupRef.current.classList.remove('hidden')
            containerRef.current.classList.remove('hidden')

            popupRef.current.classList.add('visible')
            containerRef.current.classList.add('visible')
            
        }

    }, [freezeScroll, setShow])

    const handleClose = () => {
        hidePopUp()
    }

    useEffect(() => {
        if (show === true) {
            showPopUp()
        } else {
            hidePopUp()
        }
    }, [hidePopUp, show, showPopUp])

    useEffect(() => {
        return () => {
            if (timeoutRef?.current) {
                clearTimeout(timeoutRef.current)
            }
        }
    }, [])

    useEffect(() => {
        if (popupRef?.current) {
            weiManager.see(popupRef, 'topic-research')
        }
    }, [weiManager])

    return (
        <div
            className="popup-container"
            style={ show ? {} : {display: 'none'} }
            ref={containerRef}
        >

            <div className="popup" ref={popupRef} >

                <button className="x-container default-button" onClick={handleClose}>
                    <X className="x" />
                </button>

                <div className="headline">

                    <div className="check-container" style={  isCorrect() ? {} : { display: 'none' } }>
                        <CheckMark className="check" />
                    </div>

                    <h1>{ isCorrect()
                        ? <><strong>🎉</strong> Parabéns, você acertou!</>
                        : <>Quase! Confira abaixo 👇</>
                    }</h1>

                </div>

                <p>
                    O Itaú se comprometeu a mobilizar <strong>R$ 1 trilhão</strong> para finanças sustentáveis até 2030! 
                    <br /><br />
                    ✨ O que isso significa?
                </p>

                <ul>
                    <li>
                        Apoio a projetos sustentáveis, como a recuperação de áreas degradadas.
                    </li>
                    <li>
                        Financiamento de construções verdes e microempreendedores.
                    </li>
                    <li>
                        Promover a inclusão de mulheres no mercado financeiro.
                    </li>
                </ul>

                <div className="source">
                    Fonte:&nbsp;

                    <a
                        href="https://www.itau.com.br/sustentabilidade/estrategia-esg/financas-sustentaveis/"
                        rel="nofollow noopener noreferrer"
                        target="_blank"
                        aria-label="Saiba mais sobre Finanças Sustentáveis no site do Itaú"
                    >
                        Finanças Sustentáveis - Itaú | Sustentabilidade (2023)
                    </a>
                </div>

            </div>

        </div>
    )
}

export default QuizSection;
