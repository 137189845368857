import React from 'react';

const ArrowRight = (props) => {

    const commonProps = {...props}
    delete commonProps.stroke; delete commonProps.storkeWidth;
    const { stroke = "black", strokeWidth = .3, size = 50 } = props

    const height = size
    const width = size


    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="none"
            stroke={stroke}
            strokeWidth={strokeWidth}
            {...commonProps}
        >

            <path d="
                M 5,5
                Q 6.1,4 8.3,5

                Q 47.3,20.3 47.5, 21
                Q 49.1,22.5 48,24
                Q 47.4,25 45.5,25.3

                L 32, 27.5
                Q 30,28 29,29
                Q 27.8,30.5 27.5,31.7 

                L 25.3,46.1
                Q 24.7,47.8 23,48
                Q 21.3,47.9 20.6,46.4

                Q 13.5,30 4.7,8

                Q 4.1,6 5,5
                Z
            " />
            
        </svg>
    );
};

export default ArrowRight;
