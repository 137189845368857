

import { useNavigate } from "react-router-dom";
import './main.css'

import whatsapp from '../../assets/whatsapp.png'
import instagram from '../../assets/instagram.png'
import linkedin from '../../assets/linkedin.png'
import email from '../../assets/email.png'

import logo from '../../assets/logo-primordial-white-text.svg'
import { useEffect, useState } from "react";

import GLOBAL from '../../services/GLOBAL'

export default function Footer(props) {

    const [links, setLinks] = useState({})

    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchLinks = async () => {
            try {
                const { links } = await GLOBAL.CONTENT;
                setLinks(links);
            } catch (error) {
                console.error('Failed to fetch Colleagues data:', error);
            }
        };

        fetchLinks();
        
    }, [])

    const navigate = useNavigate()

    return (
        <div className="footer-container">
            <div className="footer-content">

                <div className="company-container">
                    <img className="logo slide-in-effect" alt="logo da primordial" src={ logo } />

                    <p className="content slide-in-effect">
                        Primordial é uma consultoria ESG comprometida em ajudar médias empresas a construir uma organização modelo em gestão ambiental.
                    </p>

                    <div className="social-medias fade-in-effect">

                        <a
                            className="item default-button"
                            href={links.whatsapp}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <img src={whatsapp} alt="Whatsapp logo" />
                        </a>

                        <a
                            className="item default-button"
                            href={links.instagram}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <img src={instagram} alt="Instagram logo" />
                        </a>

                        <a
                            className="item default-button"
                            href={links.linkedin}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <img src={linkedin} alt="LinkedIn logo" />
                        </a>
                        
                        <a
                            className="item default-button"
                            href={"mailto:"+links.email}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <img src={email} alt="Email icon" />
                        </a>

                    </div>
                </div>

                <div className="menu">
                    <h1 className="title fade-in-effect">Menu</h1>

                    <a
                        className="item default-button fade-in-effect"
                        href='/about'
                        target='_blank'
                        rel='noreferrer noopener'
                        onClick={ e => { e.preventDefault(); navigate('/about')} }
                    >
                        Sobre Nós
                    </a>

                    <a
                        className="item default-button fade-in-effect"
                        href='/services'
                        target='_blank'
                        rel='noreferrer noopener'
                        onClick={ e => { e.preventDefault(); navigate('/services')} }
                    >
                        Serviços
                    </a>

                    <a
                        className="item default-button fade-in-effect"
                        href='/contact'
                        target='_blank'
                        rel='noreferrer noopener'
                        onClick={ e => { e.preventDefault(); navigate('/contact')} }
                    >
                        Contato
                    </a>

                    <a
                        className="item default-button fade-in-effect"
                        href='/policy'
                        target='_blank'
                        rel='noreferrer noopener'
                        onClick={ e => { e.preventDefault(); navigate('/policy')} } 
                    >
                        Política de Privacidade
                    </a>

                    <a
                        className="item default-button fade-in-effect"
                        href='/terms'
                        target='_blank'
                        rel='noreferrer noopener'
                        onClick={ e => { e.preventDefault(); navigate('/terms')} }
                    >
                        Termos de Uso
                    </a>

                </div>

            </div>
            <div className="copyright">© 2024 Primordial Consultoria Ambiental. Todos os direitos reservados.</div>
        </div>
    )
}