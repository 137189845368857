
import { useRef, useEffect } from "react";

/**
 * TypingEffect Component
 *
 * This component applies the typing effect to the element it wraps.
 * @param {boolean} [props.repeat=false] Whether the animation should repeat when the element becomes visible again.
 * @param {Number} [props.speed=1] How fast it will write
 */
const TypingEffect = ({ children, repeat = false, speed = 1 }) => {
    const elementRef = useRef(null);
    const originalTexts = useRef([]);
    const hasPlayed = useRef(false); // Tracks if the animation has already played
    const trigger = useRef(false);

    useEffect(() => {
        if (!elementRef.current) return;

        const element = elementRef.current;

        const observerOptions = {
            root: null, // Observe visibility relative to the viewport
            threshold: 0.5, // Trigger when 50% of the element is visible
        };

        // Save the original text nodes and their elements
        if (originalTexts.current.length === 0) {
            const textNodes = extractTextNodes(element);
            originalTexts.current = textNodes.map(({ node, text }) => {
                node.textContent = ''; // Clear text content for the typing effect
                return { node, text };
            });
        }

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (!repeat && hasPlayed.current) return; // Skip if repeat is false and animation has played
                    hasPlayed.current = true; // Mark animation as played
                    trigger.current = true;
                    typeTextSequence(originalTexts.current);
                } else if (repeat) {
                    // Reset for repeating animations
                    trigger.current = false;
                    originalTexts.current.forEach(({ node }) => (node.textContent = ''));
                }
            });
        }, observerOptions);

        observer.observe(element);

        /**
         * Extracts text nodes and their parent elements recursively.
         *
         * @param {HTMLElement} element - The root element to extract text nodes from.
         * @returns {Array} - An array of objects, each containing a text node and its text content.
         */
        function extractTextNodes (element) {
            const nodes = [];
            const walk = (node) => {
                if (node.nodeType === Node.TEXT_NODE && node.nodeValue.trim()) {
                    nodes.push({ node, text: node.nodeValue });
                } else if (node.nodeType === Node.ELEMENT_NODE) {
                    node.childNodes.forEach(walk);
                }
            };
            walk(element);
            return nodes;
        };

        /**
         * Simulates typing effect sequentially for all text nodes.
         *
         * @param {Array} textNodes - Array of objects containing nodes and their text content.
         */
        function typeTextSequence(textNodes) {
            let currentIndex = 0;

            const typeNextText = () => {
                if (!trigger.current || currentIndex >= textNodes.length) return;

                const { node, text } = textNodes[currentIndex];
                let charIndex = 0;

                const typeNextLetter = () => {
                    if (!trigger.current) return;

                    if (charIndex < text.length) {
                        node.textContent += text[charIndex];
                        charIndex++;

                        const delay = Math.random() * ((50 + 25) / speed) ; // Random delay between 50ms and 150ms
                        setTimeout(typeNextLetter, delay);
                    } else {
                        currentIndex++;
                        setTimeout(typeNextText, 200 / speed); // Small delay before starting the next node
                    }
                };

                typeNextLetter();
            };

            typeNextText();
        };

        return () => {
            observer.disconnect(); // Cleanup observer on component unmount
        };
    }, [repeat, speed]);

    return <div ref={elementRef}>{children}</div>;
}

export default TypingEffect;