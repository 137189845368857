
import { useEffect, useRef, useContext } from "react"

import TypingEffect from '../../../modules/animators/TypingEffect'

import Triangle from "../../../SVGR/Triangle"

import {GlobalContext} from '../../../GlobalContext'
import { useNavigate } from "react-router-dom"

const ServicesSection = () => {

    const services = [
        "IBAMA",
        "Licenciamento Ambiental"
    ]

    const navigate = useNavigate()
    const {weiManager} = useContext(GlobalContext)

    const servicesGridRef = useRef()

    const handleGoTo = (e, url) => {
        e.preventDefault()
        navigate(url)
    }

    useEffect(() => {
        if (servicesGridRef?.current) {
            weiManager.see(servicesGridRef, 'topic-research')
        }
    }, [weiManager])
    
    return (
        <section className="services-section" ><div className="container">
            
            <div className="header">
                <TypingEffect speed={1.3} >👉 Serviços em destaque</TypingEffect>
                <Triangle className="triangle" />
            </div>

            <div className="services-container">{ services.map( (service, index) => (
                <a
                    className="service"
                    href={`/services?service_group=${service}`}
                    onClick={ e => handleGoTo(e, `/services?service_group=${service}`) }
                    key={index}
                >
                    <div className="image-container fade-in-effect">
                        <img className="image" src={`/images/destaques-${
                            service.replace(' ', '-').toLowerCase()
                        }.png`} alt={`Imagem ilustrativa: ${service}`} />
                    </div>
                    <h1 className="slide-in-effect">{service}</h1>
                </a>
            ))}</div>

            <a className="btn default-button" href="/services" onClick={ e => handleGoTo(e, `/services`) }>
                Todos os serviços
            </a>
        
        </div></section>
    )
}


export default ServicesSection;