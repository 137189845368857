
import { useEffect, useState } from "react";

const FadeInEffectController = () => {
    
    const trigger = 0.1
    const [elementConfigs, setElementConfigs] = useState([]);

    useEffect(() => {

        // Function to find all elements with the class 'fade-in-effect'
        const findElementsWithTheClass = () => {
            
            const elements = document.querySelectorAll('.fade-in-effect');
            
            const newElementConfigs = Array.from(elements).map((element) => {
                if (!element.classList.contains('fade-in-effect-active')) {
                    return {
                        element,
                        isVisible: false,
                        hasBeenVisible: false,
                    };
                }
                return null;
            }).filter(item => item !== null);

            setElementConfigs(prev => {
                return newElementConfigs.map( currConf => {
                    const configAlreadyFound = prev.find(conf => conf.element === currConf.element)
                    return configAlreadyFound ? configAlreadyFound : currConf
                })
            });

        };  
    
        // Call the function when the component mounts
        findElementsWithTheClass();

    }, []);

    useEffect(() => {
    
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const [elementCfg, elementIndex] = elementConfigs.reduce(
                        (prevResult, currElementCfg, index) => {
                            if (entry.target === currElementCfg.element) {
                                return [currElementCfg, index];
                            } else {
                                return prevResult;
                            }
                        },
                        [undefined, undefined]
                    );
        
                    if (!elementCfg) return;
        
                    if (entry.isIntersecting && !elementCfg.hasBeenVisible) {
                        setElementConfigs((prevConfigs) =>
                            prevConfigs.map((config, i) =>
                                i === elementIndex
                                    ? { ...config, isVisible: true, hasBeenVisible: true }
                                    : config
                            )
                        );
                    }
                });
            },
            { threshold: trigger }
        );
        

        elementConfigs.forEach((config) => {
            if (config?.element) {
                if (!config?.hasBeenVisible) {
                    observer.observe(config.element)
                }
            }
        });
    
        // Cleanup
        return () => {
            observer.disconnect()
        };

    }, [elementConfigs]);

    useEffect(() => {
        elementConfigs.forEach((config) => {
            if (config?.element) {
                if (config.isVisible) {
                    config.element.classList.add('fade-in-effect-active');
                } else {
                    config.element.classList.remove('fade-in-effect-active');
                }
            }
        });
    }, [elementConfigs])

    return null;

};

export default FadeInEffectController;
