
import { useEffect, useRef, useState, useContext } from 'react'

import Line from '../../../SVGR/Line'
import PlusSign from '../../../SVGR/PlusSign'

import Slider from './Slider'

import { GlobalContext } from '../../../GlobalContext'

const ESGWorth = () => {

    const ESGBenefits = [
        { 
            title: '+30% de Valorização',
            description: 'Implementar práticas ESG pode aumentar em até 30% o valor dos imóveis, atraindo compradores conscientes e valorizando a sustentabilidade no mercado imobiliário.'
        },
        { 
            title: '+85% de Investidores',
            description: 'Empreendimentos com estratégias ESG atraem até 85% mais investidores, devido ao alinhamento com critérios éticos e sustentáveis, cada vez mais valorizados no mercado financeiro.'
        },
        { 
            title: '+70% de Fidelização',
            description: 'Clientes e inquilinos demonstram 70% mais fidelidade a marcas e projetos que adotam práticas ESG, destacando o compromisso com a sustentabilidade e responsabilidade social.'
        },
        { 
            title: '-20% de Riscos',
            description: 'Adoção de ESG no setor imobiliário pode reduzir em 20% os riscos operacionais e financeiros, com foco em conformidade regulatória e gestão ambiental responsável.'
        },
        { 
            title: 'Melhora de Imagem',
            description: 'Projetos que integram ESG reforçam a reputação da empresa, associando-a a práticas éticas e sustentáveis, o que melhora significativamente sua imagem no mercado.'
        },
        { 
            title: 'Diminuição de Multas',
            description: 'Empreendimentos ESG-compliant minimizam riscos de multas e sanções, garantindo conformidade com regulamentações ambientais e sociais vigentes no setor imobiliário.'
        }
    ];

    const benefitRef = useRef()
    const descriptionRefs = useRef({})
    const [selectedItem, setSelectedItems] = useState([])
    const {weiManager} = useContext(GlobalContext)


    const toggleSelection = (itemKey) => {
        setSelectedItems(prev => {
            if (itemKey == null) return prev;

            if (prev === itemKey) {
                return undefined
            } 

            return itemKey

        })
    }

    useEffect(() => {
        // This will make descriptions visible after finishing the accordion-out animation

        const timeout = setTimeout(() => {
            Object.values(descriptionRefs.current).forEach( el => {
                el.style.visibility = 'visible'
            })
        }, 900)

        return () => {
            clearTimeout(timeout)
        }
    }, [])

    useEffect(() => {
        if (benefitRef?.current) {
            weiManager.see(benefitRef, 'topic-research')
        }
    }, [weiManager])

    return (
        <section className="esg-worth">

            <div className="top">

                <div className="header" >
                    <h2 className="title slide-in-effect">
                        Como o ESG gera valor <br /> no mercado imobiliário?
                    </h2>

                    <div className="line"></div>

                    <h3 className="subtitle slide-in-effect">Aquisição de investimentos</h3>
                </div>

                <InteractiveFrame />

            </div>

            {/* ESG Benefits */}

            <div className="bottom slide-in-effect" ref={benefitRef} >{
                ESGBenefits.map((item, index) => (
                    <div className="item-container" key={index}>

                        <div
                            className={`item ${ selectedItem === index ? '' : 'unselected' }`}
                            style={ selectedItem === index ? { zIndex: 200 } : null }
                        >
                            <div className="title">
                                <span>
                                    
                                    <h2>{item.title}</h2>

                                    <div className="btn"
                                        style={ selectedItem === index ? {backgroundColor: '#000'} : {} }
                                        onClick={ () => toggleSelection(index) }
                                    >
                                        <PlusSign
                                            className={`sign ${ selectedItem === index ? 'hide' : '' }`}
                                        />
                                        <Line
                                            className={`sign ${ selectedItem === index ? '' : 'hide' }`}
                                            style={{stroke: '#fff'}}
                                        />
                                    </div>

                                </span>
                            </div>

                        </div>
                        
                        <div
                            className={`description-container ${ selectedItem === index ? '' : 'hidden' }`}
                            style={{
                                zIndex: selectedItem === index ? 150: null,
                                visibility: 'hidden' // This will avoid accordion-out effect to be visible in component mounting
                            }}
                        >
                            <div
                                ref={ (el) => {
                                    if (el) descriptionRefs.current[index] = el
                                }}
                                className={`description ${
                                    selectedItem === index ? 'visible' : 'hidden'
                                }`}
                            >
                                {item.description}
                            </div>
                        </div>

                    </div>
                ))
            }</div>

        </section>
    )
}


const InteractiveFrame = () => {

    const [amount, setAmount] = useState(1)

    const calculateRows = (total = amount) => {

        const rows = Math.ceil(total / 4)
        const itemsPerRow = Math.floor(total / rows)
        const remainder = total % rows

        const result = Array(rows).fill(itemsPerRow)
        const middleRowIndex = Math.floor(rows / 2)
        result[middleRowIndex] += remainder

        return result
    }

    return (
        <div className="interactive-frame">

            <div className="grid fade-in-effect">{ calculateRows().map((itemsInRow, rowIndex) => (
                <div key={rowIndex} className="row">
                    {Array.from({ length: itemsInRow }).map((_, index) => (
                        <div key={index} className="item" style={{ '--delay': index }}>
                            🏡
                        </div>
                    ))}
                </div>
            ))}</div>

            <p className="slider-title">Investimento em ESG</p>

            <Slider value={amount} setValue={setAmount} max={10} />

        </div> 
    )
}

export default ESGWorth;