import React from 'react';

/**
 * 
 * @param {String} props.stroke CSS like Stroke color
 * @param {Number} props.strokeWidth
 * @param {Number} props.size Width/Height size in pixels 
 * @returns 
 */
const ArrowRight = (props) => {

  const commonProps = {...props}
  delete commonProps.stroke; delete commonProps.storkeWidth; delete commonProps.size;
  const { stroke = "black", strokeWidth = 1, size = 30 } = props

  const width = size;
  const thickness = width/15 * strokeWidth
  const height = thickness * 10;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      stroke={stroke}
      strokeWidth={thickness}
      strokeLinecap="round"
      {...commonProps}
    >
      <line x1={thickness} y1={thickness} x2={width - thickness} y2={thickness} />
      <line x1={thickness} y1={height / 2} x2={width - thickness} y2={height / 2} />
      <line x1={thickness} y1={height - thickness} x2={width - thickness} y2={height - thickness} />
    </svg>
  );
};

export default ArrowRight;
