import React from 'react';

/**
 * 
 * @param {String} props.stroke The color of the stroke.
 * @param {String} props.strokeWidth The width of the stroke.
 * @returns {JSX.Element}
 */
const CheckMark = (props) => {
  const commonProps = { ...props };
  const { stroke = "black", strokeWidth = 1, size = 20 } = props;

  const thickness = size / 10 * strokeWidth;
  const width = size;
  const height = size / 1.18;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      stroke={stroke}
      strokeWidth={thickness}
      strokeLinecap="round"
      {...commonProps}
    >
      {/* Check mark */}
      <path
        d={`
          M ${thickness * 2},${height / 2}

          L ${width / 2.3},${height - thickness * 2}
          L ${width / 2.3},${height - thickness * 2}
          L ${width / 2.3},${height - thickness * 2}

          L ${width - thickness * 2},${thickness * 2}
        `}
      />
    </svg>
  );
};

export default CheckMark;
