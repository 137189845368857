
import { useRef, useCallback, useEffect, useState } from "react";

import EquilateralTriangle from '../../../SVGR/EquilateralTriangle'

/**
 * @param {Number} props.value Input value
 * @param {Number} props.max Max input value
 * @param {function} props.setValue Input value setter
 */
const Slider = ({ value, setValue, max }) => {

    const activeRef = useRef(),
        thumbRef = useRef(),
        sliderRef = useRef();

    const isDragging = useRef(false);
    const [showPercentage, setShowPercentage] = useState(false);

    const exitDelay = 1800; // ms

    const calcStyle = useCallback(() => {
        if (!sliderRef.current) return { left: "0%" };

        const sliderWidth = sliderRef.current.offsetWidth;
        const thumbWidth = thumbRef.current.offsetWidth;
        const percentage = Math.min(value / max, 1);
        const thumbPosition = percentage * sliderWidth;

        const left = thumbPosition - thumbWidth / 2;

        return {
            left: `${left}px`,
            width: `${percentage * 100}%`,
            percentage: Math.round(percentage * 100)
        };
    }, [max, value]);

    useEffect(() => {
        const active = activeRef.current;
        const thumb = thumbRef.current;

        const { left, width } = calcStyle();

        if (active) active.style.width = width;
        if (thumb) thumb.style.left = left;

    }, [calcStyle]);

    useEffect(() => {
        setShowPercentage(prev => prev ? prev : !prev )
        const timer = setTimeout(() => { if (!isDragging.current) setShowPercentage(false) }, exitDelay);
        return () => clearTimeout(timer);
        
    }, [value, setShowPercentage]);

    const handleSliderClick = (e) => {
        if (!sliderRef.current) return;

        const rect = sliderRef.current.getBoundingClientRect();
        const clickX = e.clientX - rect.left;
        const newValue = ((clickX / rect.width) * max).toFixed(2);
        setValue(Math.min(Math.max(newValue, 0), max));
    };

    const handleMouseDown = (e) => {
        isDragging.current = true;
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
    };

    const handleTouchStart = (e) => {
        isDragging.current = true;
        document.addEventListener("touchmove", handleTouchMove);
        document.addEventListener("touchend", handleTouchEnd);
    };

    const handleMouseMove = (e) => {
        if (!isDragging.current || !sliderRef.current) return;

        const rect = sliderRef.current.getBoundingClientRect();
        const mouseX = e.clientX - rect.left;
        const newValue = ((mouseX / rect.width) * max).toFixed(2);
        setValue(Math.min(Math.max(newValue, 0), max));
    };

    const handleTouchMove = (e) => {
        if (!isDragging.current || !sliderRef.current) return;

        const rect = sliderRef.current.getBoundingClientRect();
        const touchX = e.touches[0].clientX - rect.left;
        const newValue = ((touchX / rect.width) * max).toFixed(2);
        setValue(Math.min(Math.max(newValue, 0), max));
    };

    const handleMouseUp = () => {
        isDragging.current = false;
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
    };

    const handleTouchEnd = () => {
        isDragging.current = false;
        document.removeEventListener("touchmove", handleTouchMove);
        document.removeEventListener("touchend", handleTouchEnd, { capture: true });
    };

    const handleDragStart = (e) => {
        e.preventDefault();
    };

    const { percentage } = calcStyle();

    return (
        <div className="slider-frame" ref={sliderRef} onClick={handleSliderClick}>
            <div className="active" ref={activeRef}></div>
            <div className="thumb"
                ref={thumbRef}
                onMouseDown={handleMouseDown}
                onTouchStart={handleTouchStart}
                onDragStart={handleDragStart}
            >
                <div className={ `tooltip ${ !showPercentage ? 'hidden' : ''}` }>
                    <EquilateralTriangle className="eqTriangle"/>
                    {percentage}%
                </div>
            </div>
        </div>
    );
};

export default Slider;
