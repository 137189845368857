import React from 'react';

const ArrowRight = (props) => {

  const commonProps = {...props}
  delete commonProps.stroke; delete commonProps.storkeWidth; delete commonProps.size;
  const { stroke = "black", strokeWidth = 1, size = 20 } = props

  const width = size;

  const thickness = width/10 * strokeWidth
  const height = thickness;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      stroke={stroke}
      strokeWidth={thickness}
      strokeLinecap="round"
      {...commonProps}
    >
      <line x1={thickness} y1={height / 2} x2={width - thickness} y2={height / 2} />
    </svg>
  );
};

export default ArrowRight;
