
const EquilateralTriangle = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="8.66"
            viewBox="0 0 10 8.66"
            fill="#000"
            {...props}
        >
            <polygon points="0, 8.66 5,0 10, 8.66" />
        </svg>
    );
}

export default EquilateralTriangle