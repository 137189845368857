
import React, { useState, useEffect, useRef } from 'react';
import './main.css';
import { useNavigate } from 'react-router-dom';

import Logo from '../../assets/logo-primordial.svg';
import MenuIcon from '../../SVGR/MenuIcon';
import X from '../../SVGR/X';

export default function Nav() {

    const navigate = useNavigate();

    const navRef = useRef();
    const lastScrollY = useRef(0);
    const swipeRef = useRef();

    const [hidden, setHidden] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const menuItems = [
        { text: '1. Sobre', href: '/about' },
        { text: '2. Serviços', href: '/services' },
        { text: '3. Contato', href: '/contact' },
        { text: '4. Proposta', href: '/requestForProposal' }
    ];

    const handleClick = ({ href }) => {
        navigate(href);
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const hanldeSwipeRight = () => {
        setMenuOpen(false)
    }

    // Função para lidar com o início do toque
    const handleTouchStart = (e) => {
        const touch = e.touches[0];
        swipeRef.current.startX = touch.clientX;
        swipeRef.current.startY = touch.clientY;
    };

    // Função para lidar com o fim do toque
    const handleTouchEnd = (e) => {
        const touch = e.changedTouches[0];
        const deltaX = touch.clientX - swipeRef.current.startX;
        const deltaY = touch.clientY - swipeRef.current.startY;

        // Verifica se o swipe foi principalmente horizontal e para a direita
        if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX > 50) {
            hanldeSwipeRight();
        }
    };

    useEffect(() => {
        const element = swipeRef.current;
        if (element) {
            element.addEventListener('touchstart', handleTouchStart);
            element.addEventListener('touchend', handleTouchEnd);

            // Cleanup dos event listeners ao desmontar o componente
            return () => {
                element.removeEventListener('touchstart', handleTouchStart);
                element.removeEventListener('touchend', handleTouchEnd);
            };
        }
    }, []);

    useEffect(() => {

        const handleScroll = () => {

            const currentScrollY = window.scrollY;

            if (currentScrollY > lastScrollY.current) {
                setHidden(true);
            } else {
                setHidden(false);
            }

            lastScrollY.current = currentScrollY;

        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [lastScrollY, setHidden]);

    const goToHomePage = () => {
        navigate('/');
    };

    return (
        <>
            <nav
                ref={navRef}
                className={`nav-bar ${hidden ? 'nav-hidden' : ''}`}
            >
                <a href='/' onClick={(e) => e.preventDefault()} className='logo-frame default-button'>
                    <img
                        src={Logo}
                        className="logo"
                        onClick={goToHomePage}
                        alt='logo da primordial'
                    />
                </a>

                <div className='menu-btn-frame default-button' onClick={toggleMenu}>
                    <MenuIcon className="menu-btn" />
                </div>
            </nav>

            {/* Menu Frame */}
            
            <div className={`menu-frame ${menuOpen ? 'open' : ''}`}>
                <div className='menu-background'></div>

                <div className="menu" ref={swipeRef}>
                    

                    <div className="x-frame default-button" onClick={toggleMenu}>
                        <X className="x-btn" />
                    </div>

                    <ul className="items">
                        {menuItems.map((item, index) => (
                            <li
                                key={index}
                                className="item default-button"
                                onClick={() => handleClick(item)}
                            >
                                <a href={item.href} onClick={(e) => e.preventDefault()}>
                                    {item.text}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>

            </div>
            
        </>
    );
}
