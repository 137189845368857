
import { useEffect, useRef, useState } from "react";

/**
 * ParallaxEffect Hook
 *
 * Adds a parallax effect to the referenced element.
 *
 * @param {React.RefObject<HTMLElement>} parentRef - The reference to the element to which the effect will be applied.
 * @param {number} speed - The speed factor of the parallax effect.
 * @param {number} max - The maximum percentage (between 0 and 1) of the element's height that will be used for the parallax translation.
 * @param {number} cameraOffsetY - The camera's Y offset relative to the center of the parentRef element.
 * For example, if cameraOffsetY is 0, the Y translation will be 0 (no effect) when the window's scroll Y matches 
 * the parentRef's offset from the top of the root element.
 * If you want the effect to be nullified when the parentRef element is vertically centered in the viewport, 
 * set cameraOffsetY to "(window.innerHeight / 2) - (parentRef.current.getBoundingClientRect().height / 2)".
 */
const ParallaxEffect = ({ parentRef, speed = 1, max, cameraOffsetY = 0 }) => {

    const [topFromParent, setTopFromParent] = useState(null)
    const [topFromRoot, setTopFromRoot] = useState(null)
    const started = useRef(false)
    
    useEffect(() => {
        if (parentRef?.current) {
            const relativeTop = parentRef.current.offsetTop
            const absoluteTop = getOffsetTopRelativeToRootElement(parentRef.current)
            setTopFromParent(relativeTop)
            setTopFromRoot(absoluteTop)
        }

        function getOffsetTopRelativeToRootElement(element) {
            let offsetTop = 0;
            let currentElement = element;
        
            while (currentElement) {
                offsetTop += currentElement.offsetTop;
                currentElement = currentElement.offsetParent;
            }
        
            return offsetTop;
        }

    }, [parentRef]);
    
    useEffect(() => {

        const handleScroll = () => {
            
            const element = parentRef?.current;

            if (
                !element ||
                topFromParent == null ||
                topFromRoot == null
            ) {
                return
            };

            const rect = element.getBoundingClientRect();

            // Get element centerY relative to Root Element
            const centerY = topFromRoot + rect.height / 2

            // Get camera Y relative to Root Element
            const cameraY = cameraOffsetY + (window.innerHeight / 2) + window.scrollY

            // Calculate centerY distance from cameraY
            const distance =  cameraY - centerY
    
            const translateY = Math.round(
                distance * (speed / 10)
            );
    
            // Limit the translation to the max percentage of the element's height
            const parentHeight = rect.height;
            const maxTranslateY = (max == null) ? null : parentHeight * max;

            const limitedTranslateY =  
                (maxTranslateY === null) ? translateY
                : Math.min(Math.max(translateY, -maxTranslateY), maxTranslateY)

            // Apply the new top acording to original style
            const render = () => {
                element.style.top = `${limitedTranslateY + topFromParent}px`;
            }

            requestAnimationFrame(render)
        };
  
        // Listen to the scroll event on the window
        window.addEventListener("scroll", handleScroll);
        window.addEventListener("wheel", handleScroll);

        // Run the effect on component mounting 
        if (!started?.current) {
            if (
                !parentRef?.current ||
                topFromParent == null ||
                topFromRoot == null
            ) {
                return
            };

            handleScroll()
            started.current = true
        }
    
        return () => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("wheel", handleScroll);
        };

    }, [parentRef, speed, max, topFromParent, topFromRoot, cameraOffsetY]);
  
    return null;
}

export default ParallaxEffect;