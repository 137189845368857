import React from 'react';

const ArrowRight = (props) => {

  const commonProps = {...props}
  delete commonProps.stroke; delete commonProps.storkeWidth;
  const { stroke = "black", strokeWidth = 1, size = 20 } = props

  const height = size;
  const width = size;

  const thickness = height/10 * strokeWidth

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      stroke={stroke}
      strokeWidth={thickness}
      strokeLinecap="round"
      {...commonProps}
    >
      {/* Horizontal line */}
      <line x1={thickness} y1={height / 2} x2={width - thickness} y2={height / 2} />
      
      {/* Vertical line */}
      <line x1={width / 2} y1={thickness} x2={width / 2} y2={height - thickness} />
    </svg>
  );
};

export default ArrowRight;
