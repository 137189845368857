
import { createContext, useEffect, useRef, useState } from "react";
import WEIManager from "./modules/WEIManager";

const GlobalContext = createContext()

const GlobalProvider = ({children}) => {

    const cookiePermition = ( getCookie('cookies-permition') === 'allowed' )

    const api = useRef({
        getData() {
            return api.current.data
        },
        setData(data) {
            api.current.data = data
        },
        data: {}
    })
    const [areCookiesAllowed, setAreCookiesAllowed] = useState(false)

    const weiManagerRef = useRef(new WEIManager())

    function allowCookies() {
        setAreCookiesAllowed(_ => true)
    }

    function declineCookies() {
        setAreCookiesAllowed(_ => false)
    }

    function getCookie(name) {
        const cookies = document.cookie
            .split('; ')
            .find(cookie => cookie.startsWith(`${name}=`));
        return cookies ? cookies.split('=')[1] : null;
    }
    
    /**
     * Sets a cookie with the specified name, value, and options.
     * 
     * @param {string} name - The name of the cookie.
     * @param {string} value - The value of the cookie.
     * @param {Object} [options={}] - An optional object that can include the following properties:
     * @param {number} [options.days] - The number of days until the cookie expires. If not specified, the cookie will be a session cookie.
     * @param {string} [options.path='/'] - The path where the cookie is valid. Defaults to `/` if not specified.
     * @param {boolean} [options.secure] - If `true`, the cookie will only be sent over secure (HTTPS) connections.
     * @param {string} [options.sameSite] - Controls the cross-site request behavior of the cookie. Can be 'Strict', 'Lax', or 'None'.
     * 
     * @returns {void} - This function does not return any value.
     * 
     * @example
     * // Set a cookie that expires in 7 days
     * setCookie('user', 'Joe', { days: 7 });
     * 
     * @example
     * // Set a secure cookie with SameSite 'Strict'
     * setCookie('session', '12345', { secure: true, sameSite: 'Strict' });
     */
    function setCookie(name, value, options = {}) {
        let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
    
        if (options.days) {
            const date = new Date();
            date.setTime(date.getTime() + options.days * 24 * 60 * 60 * 1000);
            cookieString += `; expires=${date.toUTCString()}`;
        }
    
        if (options.path) {
            cookieString += `; path=${options.path}`;
        } else {
            cookieString += `; path=/`;
        }
    
        if (options.secure) {
            cookieString += `; Secure`;
        }
    
        if (options.sameSite) {
            cookieString += `; SameSite=${options.sameSite}`;
        }
    
        document.cookie = cookieString;
    }

    useEffect(() => {

        const {getData, setData} = api.current

        const weiManager = weiManagerRef.current
        weiManager.setInterface({getData, setData})

        setAreCookiesAllowed(cookiePermition)

        return () => {
            if (weiManager) weiManager.cleanupFunction()
        }

    }, [api, cookiePermition])

    useEffect(() => {
        areCookiesAllowed
            ? weiManagerRef.current.allowSavingData()
            : weiManagerRef.current.declineSavingData()
    }, [areCookiesAllowed])

    return (
        <GlobalContext.Provider value={{

            weiManager: weiManagerRef.current,
            areCookiesAllowed,
            allowCookies,
            declineCookies,
            getCookie,
            setCookie

        }}>
            {children}
        </GlobalContext.Provider>
    )

}

export {GlobalContext, GlobalProvider}
