
import PlusSign from '../../../SVGR/PlusSign'
import Line from '../../../SVGR/Line'
import { useContext, useEffect, useRef, useState } from 'react';
import { GlobalContext } from '../../../GlobalContext';

const FAQSection = () => {

    const questions = [
        {
            title: "ESG serve para empresas pequenas?",
            answer: "Sim, o ESG é aplicável a empresas de todos os tamanhos. Para pequenas empresas, ele pode ser adaptado às suas realidades, focando em ações de alto impacto e baixo custo. Implementar práticas ESG ajuda a melhorar a eficiência operacional, fortalecer a reputação e atrair novos clientes e investidores."
        },
        {
            title: "Quanto tempo leva para colher resultados?",
            answer: "Os resultados do ESG podem ser percebidos tanto no curto quanto no longo prazo. Por exemplo, a redução de desperdícios pode gerar economia quase imediata, enquanto benefícios como maior reputação e acesso a novos mercados podem levar meses ou anos. Com nossa metodologia PDCA, garantimos um progresso contínuo e estruturado."
        },
        {
            title: "Consigo retorno financeiro ou benefícios intangíveis?",
            answer: "Sim, o ESG pode gerar ambos. Retornos financeiros incluem economia em custos operacionais e maior acesso a financiamento, enquanto os benefícios intangíveis abrangem melhor reputação, maior engajamento dos colaboradores e fidelização de clientes. Trabalhamos para maximizar esses resultados com base nas suas metas."
        },
        {
            title: "Quais são os fundos de investimentos?",
            answer: "Existem diversos fundos de investimento focados em empresas com boas práticas ESG. Esses fundos geralmente priorizam negócios que demonstram compromisso com sustentabilidade, governança sólida e impacto social positivo. Podemos ajudá-lo a identificar e se preparar para acessar essas oportunidades."
        },
        {
            title: "Como o ESG funciona no longo prazo?",
            answer: "O ESG é uma estratégia de longo prazo que promove sustentabilidade e resiliência. Empresas que adotam essas práticas tendem a se destacar em tempos de mudanças regulatórias, crises ou mudanças no mercado. Nosso trabalho na Primordial é ajudá-lo a construir uma base sólida para esses benefícios duradouros."
        },
        {
            title: "Por que a Primordial?",
            answer: "Na Primordial, temos empatia pelo cliente, compreendemos as suas necessidades e adaptamos nossas soluções ao seu contexto. Além disso, usamos a metodologia PDCA para garantir melhorias contínuas e contamos com uma equipe especializada em ESG para oferecer resultados efetivos."
        },
        {
            title: "Qual o custo de implementação do ESG?",
            answer: "O custo varia conforme o tamanho da empresa e o escopo do projeto. No entanto, nosso foco é desenvolver soluções ESG acessíveis e personalizadas, garantindo que os investimentos tragam retornos financeiros e benefícios tangíveis no médio e longo prazo."
        },
        {
            title: "“Acho o ESG muito complicado!”",
            answer: "Entendemos que o ESG pode parecer desafiador no início, mas nossa missão na Primordial é simplificar esse processo para você. Com nossa metodologia estruturada e orientação personalizada, tornamos o ESG algo acessível e prático, independentemente do nível de experiência da sua empresa."
        },
    ];

    const questionRefs = useRef([])
    const containerRef = useRef()

    const [selectedIndex, setSelectedIndex] = useState(null)
    const [selectedQuestion, setSelectedQuestion] = useState({title: 'Title', answer: 'Answer Answer Answer Answer Answer Answer Answer Answer Answer Answer Answer Answer Answer Answer Answer Answer Answer Answer Answer Answer Answer Answer Answer.'})

    const {weiManager} = useContext(GlobalContext)

    const handleSelect = (index) => {
        if (!questionRefs?.current?.length) return

        setSelectedIndex(prev => {
            if (prev === index) {
                return null
            } else {
                setSelectedQuestion(prev => (questions?.length !== 0) ? questions[index] : prev)
                return index
            }
        })
        
    }
    
    useEffect(() => {
        if (containerRef?.current) {
            weiManager.see(containerRef, 'topic-research')
        }
    }, [weiManager])

    return (
        <section className="faq-section"><div className="container" ref={containerRef} >
            
            <h1 className='slide-in-effect'>
                Principais perguntas sobre ESG
            </h1>

            <div className="questions-container" >

                <div className="questions" style={
                    selectedIndex !== null
                    ? { left: 'calc(-100% - .5em)' }
                    : {}
                }>{
                    questions.map( (question, index) => (

                        <div
                            className="question slide-in-effect"
                            key={index}
                            ref={ e => questionRefs.current[index] = e }
                        >   

                            <div className="headline">
                                {index+1}. {question.title}

                                <button
                                    className="default-button"
                                    onClick={ () => handleSelect(index) }
                                >
                                    <PlusSign className="sign" />
                                </button>
                            </div>

                        </div>

                        ))
                }</div>

                <div className="answer-container" style={
                    selectedIndex !== null
                    ? { left: '0' }
                    : {}
                }>

                    <h2>

                        {selectedQuestion.title}

                        <button
                            className="default-button"
                            onClick={ () => handleSelect(selectedIndex) }
                        >
                            <Line className="sign" style={{ width: "80% " }} />
                        </button>

                    </h2>

                    <p>
                        {selectedQuestion.answer}
                    </p>
                </div>

            </div>
            
        </div></section>
    )
}

export default FAQSection;