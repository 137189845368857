
import {useEffect, useRef} from 'react'
import { useNavigate } from 'react-router-dom'

import CustomCursor from './CustomCursor'
import ParallaxEffect from '../../../modules/animators/ParallaxEffect'
import TypingEffect from '../../../modules/animators/TypingEffect'

import CustomCursorIcon from '../../../SVGR/CustomCursorIcon'
import Arrow from '../../../SVGR/Arrow'

import laysFrontViewBackground from '../../../assets/lays-front-view-background.jpg'
import laysImage from '../../../assets/lays-front-view.png'

import LazyImage from '../../../services/LazyImage'
import GLOBAL from '../../../services/GLOBAL'

const Hero = () => {

    const laysBackgroundRef = useRef()
    const foregroundFrameRef = useRef()
    const imageContainerRef = useRef()
    const heroCursorFrameRef = useRef()
    const foregroundCursorContainerRef = useRef()
    const sectionRef = useRef()

    const lastScrollToRef = useRef(null)

    const navigate = useNavigate()


    const handleCursorExit = () => {
        if (!heroCursorFrameRef.current) return

        heroCursorFrameRef.current.classList.add('cursor-exit')
    }

    const handleCursorEnter = () => {
        if (!heroCursorFrameRef.current) return

        heroCursorFrameRef.current.classList.remove('cursor-exit')
    }

    const handleCTA = (e) => {
        e.preventDefault()
        navigate('/requestForProposal')
    }
    
    useEffect(() => {
        const timeout = setTimeout(() => {
            window.scrollTo(0, 1);
        }, 100);
    
        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        if (!sectionRef.current) return;


        const rect = sectionRef.current.getBoundingClientRect();
        const endPosition = rect.y + rect.height - window.visualViewport.height;
        
        const duration = 1000;
        const delay = 1500;

        let initialScroll, startTime;

        const easeInOutQuad = (t) => {
            return t < 0.5
                ? (2 * t * t)
                : (-1 + (4 - 2 * t) * t)
        };

        const animateScroll = (currentTime) => {

            if (!startTime) startTime = performance.now()
            if (!initialScroll) initialScroll = window.scrollY

            const elapsedTime = currentTime - startTime
            const t = Math.min(elapsedTime / duration, 1)

            const scrollPosition = initialScroll + (endPosition - initialScroll) * easeInOutQuad(t)
            
            const lastScrollTo = lastScrollToRef.current
            if (lastScrollTo !== null) {
                // If the user manually interacts with the scroll, the scrolling animation is stopped.
                if (Math.abs(lastScrollTo - window.scrollY) > 1.5) {
                    return // Stop scrolling animation when the user interacts with the scroll
                }
            } 
            
            window.scrollTo(0, scrollPosition)
            // Updates the last requested scroll position (lastScrollToRef.current) with the new position.
            lastScrollToRef.current = scrollPosition

            if (t < 1) {
                requestAnimationFrame(animateScroll)
            }
        };

        const timeout = setTimeout(() => {
            if (window.scrollY < 20) requestAnimationFrame(animateScroll)
        }, delay)

        return () => {
            clearTimeout(timeout)
        }
    }, []);


    return (
        <section className="hero" ref={sectionRef} >

            <div className="image-container" ref={imageContainerRef} >

                {/* */}
                <ParallaxEffect parentRef={laysBackgroundRef} speed={1.2} max={0.15} />

                <div className="background-frame" ref={laysBackgroundRef} >
                    <LazyImage 
                        className='background-image'
                        alt="Sala branca com folhagem central na parede"
                        thumbnail = { laysFrontViewBackground }
                        lightSource = { 'getImage/lays-front-view-background.jpg' }
                        heavySource = { 'getImage/lays-front-view-background.png' }
                        baseURL = { GLOBAL.BACKEND_BASE_URL }
                    />
                </div>  

                <div className="foreground-frame" ref={foregroundFrameRef}>
                    
                    <ParallaxEffect parentRef={foregroundFrameRef} speed={1.5} max={0.15} />
                    
                    <LazyImage 
                        className='foreground-image'
                        alt="Lays Andrade"
                        thumbnail = { laysImage }
                        lightSource = { 'getImage/lays-front-view-light.png' }
                        heavySource = { 'getImage/lays-front-view.png' }
                        baseURL = { GLOBAL.BACKEND_BASE_URL }
                    />

                    <div className="custom-cursor-container" ref={foregroundCursorContainerRef}>
                        <CustomCursor
                            onEnter={handleCursorEnter}
                            onExit={handleCursorExit}
                            exitDelay={500}
                            parentRef={foregroundCursorContainerRef}
                        >

                            <div className="hero-cursor-frame" ref={heroCursorFrameRef}>
                                <CustomCursorIcon className='hero-cursor-icon' />
                                Lays Andrade - CEO da Priomordial
                            </div>
                            
                        </CustomCursor>
                    </div>

                </div>

            </div>

            <div className="content"> 
                
                <span>
                    <TypingEffect  speed={1.3} >
                        <div className="header">
                            <h1 className="title">
                                <div className="typing-effect"><strong>Prosperidade</strong> & <strong>valor</strong><br /></div>
                                <div className="typing-effect">no mercado imobiliário</div>
                            </h1>
                            
                            <h2 className="subtitle">Criamos sinergia entre negócios e sustentabilidade com a implementação estratégica do ESG.</h2>
                        </div>
                    </TypingEffect>
                    

                    <a className="cta" href="/requestForProposal" onClick={ handleCTA }>
                        Faça parte
                        <Arrow className="arrow"/>
                    </a>
                </span>
                
            </div>

        </section>
    )
}

export default Hero;