
import { useEffect, useRef, useContext } from 'react';

import TypingEffect from '../../../modules/animators/TypingEffect';

import Triangle from '../../../SVGR/Triangle';
import lays from '../../../assets/lays-ipad.png'

import LazyImage from '../../../services/LazyImage'
import GLOBAL from '../../../services/GLOBAL'

import { GlobalContext } from '../../../GlobalContext'

const WhatESGIs = () => {

    const imageRef = useRef()
    const contentRef = useRef()

    const {weiManager} = useContext(GlobalContext)

    
    useEffect(() => {

        const oberver = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible')
                }
            },
            {threshold: 0.2}
        )

        if (imageRef?.current) {
            oberver.observe(imageRef.current)
        }

        return () => {
            oberver.disconnect()
        }

    }, [])

    useEffect(() => {
        if (contentRef?.current) {
            weiManager.see(contentRef, 'topic-research')
        }
    }, [weiManager])

    return (
        <section className="what-esg-is">

            <div className="content-container" ref={contentRef} >
                
                <div className="content">
                    <div className="headline slide-in-effect">
                        <TypingEffect speed={1.3} >👉 O que é ESG?</TypingEffect>
                        <Triangle className="triangle" />
                    </div>
                    <p className='slide-in-effect'>
                        ESG significa Ambiental, Social e Governança. É um conjunto de práticas que as empresas&nbsp;
                        adotam para serem mais responsáveis...
                    </p>
                </div>

                <div className="content">
                    <div className="headline slide-in-effect">
                        <TypingEffect speed={1.3} >👉 Para que serve ESG?</TypingEffect>
                        <Triangle className="triangle" />
                    </div>
                    <p className='slide-in-effect'>
                        Implementar ESG no mercado imobiliário torna sua empresa mais atrativa para investidores,&nbsp;
                        valoriza os imóveis, reduz riscos legais e multas... 
                    </p>
                </div>

            </div>

            <div className="image-container">
                

                <div className="image-frame">
                <div className="gradient-container">
                    <div className="gradient"></div>
                </div>

                    <LazyImage
                        ref={imageRef}
                        className='image'
                        alt="Lays em pé segurando um IPad"
                        thumbnail = { lays }
                        lightSource = { 'getImage/lays-ipad-light.png' }
                        heavySource = { 'getImage/lays-ipad.png' }
                        baseURL = { GLOBAL.BACKEND_BASE_URL }
                    />

                </div>
            </div>
            
        </section>
    )
}

export default WhatESGIs;