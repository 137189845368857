
import React from "react";

const Triangle = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox={`0 0 10 10`}
            fill="#000"
            stroke="#0000"
            strokeWidth="0"
            {...props}
        >
            <polygon points="0,0 10,0 10,10" />
        </svg>
    )
}

export default Triangle