
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import './App.css';
import AppRoutes from './AppRoutes.jsx'

import { GlobalProvider } from "./GlobalContext.jsx";

import FadeInEffectController from './animation-controllers/FadeInEffectController';
import SlideInEffectController from "./animation-controllers/SlideInEffectController.jsx";

function App() {

    const location = useLocation()

    useEffect(() => {
        const handleBeforeInstallPrompt = (e) => {
          // Prevent browser from showing the install PWA pop-up
          e.preventDefault();
        };
    
        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    
        // Cleanup event listener on component unmount
        return () => {
          window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
      }, []);

    return (
        <div className="App">
          <GlobalProvider> {/* Provides global context to share the application's WEIManager instance across components */}
            
            <FadeInEffectController key={location.pathname} /> {/* Controls Fade In Effects */}
            <SlideInEffectController key={location.pathname} /> {/* Controls Slide In Effects */}
            
            <AppRoutes />

          </GlobalProvider>
        </div>
    );
}

export default App;
