

import { useContext, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import Arrow from '../../../SVGR/Arrow'
import { GlobalContext } from '../../../GlobalContext'

import photo from '../../../assets/city-aerial-photo.jpg' 

import LazyImage from '../../../services/LazyImage'
import GLOBAL from '../../../services/GLOBAL'

const ESGIsThePresent = () => {

    const navigate = useNavigate()
    const imageFrameRef = useRef()
    const ctaContainerRef = useRef()
    const {weiManager} = useContext(GlobalContext)

    const handleClick = (e) => {
        e.preventDefault()
        navigate('/contact')
    }

    useEffect(() => {
        if (!imageFrameRef?.current) return

        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                imageFrameRef.current.classList.add('visible')
                observer.disconnect()
            }
        }, { threshold: 0.2 })

        observer.observe(imageFrameRef.current)

        return () => { observer.disconnect() }

    }, [])

    useEffect(() => {
        if (ctaContainerRef?.current) {
            weiManager.see(ctaContainerRef, 'service-interest')
        }
    }, [weiManager])

    return (
        <section className="esg-is-the-present">

            <div className="image-frame" ref={imageFrameRef}>
                    
                <LazyImage 
                    className='image'
                    alt="Visão aérea de uma cidade"
                    thumbnail = { photo }
                    lightSource = { 'getImage/city-aerial-photo.jpg' }
                    heavySource = { 'getImage/city-aerial-photo.png' }
                    baseURL = { GLOBAL.BACKEND_BASE_URL }
                />

                <div className="cta-container" ref={ctaContainerRef} >

                    <div className='fade-in-effect' >
                        <h1>
                            ESG não é o futuro, é o presente!
                        </h1>

                        <a  
                            className='default-button'
                            href="/contact"
                            onClick={handleClick}
                        >
                            Saiba mais <Arrow className="arrow" bodyLength={2.5} />
                        </a>
                    </div>

                </div>

            </div>

        </section>
    )
}

export default ESGIsThePresent;